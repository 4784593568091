.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgray;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  .image-container {
    width: 23%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name,
  .quantity,
  .price {
    width: 23%;
  }
  .quantity {
    // padding-left: 20px;
    display: flex;
    // justify-content: space-evenly;
  }
  .remove-button {
    margin-left: 12px;
    cursor: pointer;
    color: #e71d36;
    background-color: rgba($color: #e71d36, $alpha: 0.1);
  }
}
