.custom-button {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px;
  font-size: 15px;
  background-color: #011627;
  color: #fdfffc;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fdfffc;
    color: #011627;
    border: 1px solid #011627;
  }
  &.inverted {
    background-color: #fdfffc;
    color: #011627;
    border: 1px solid #011627;

    &:hover {
      background-color: #011627;
      color: #fdfffc;
      border: none;
      animation: addToCart 300ms forwards;
    }
  }
}
@keyframes addToCart {
  to {
    // height: +20px;
    // border-radius: 50%;
    // padding: 0;
    // margin-top: 25px;
    // min-width: 20px;
  }
}
