.cart-dropdown {
  position: absolute;
  width: 300px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 70px;
  z-index: 10;

  .empty-message {
    margin: auto;
  }

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  bottom {
    margin-top: auto;
  }
}
